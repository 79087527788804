<template>
  <Header title="Service Details" />

  <Container>
    <h2>Enhancing Owner/Shareholder R.O.I</h2>
    <p>
      Bryan and Hedden can assist business owners and professionals with the
      design and implementation of creative, tax effective strategies to improve
      the value of their business and personal net worth.
      <br /><br />
      Our creative planning process encompasses all or some of the following
      concepts to enhance the owners' personal financial position.
      <br /><br />
      In this process we would typically review the opportunities that exist in
      the following areas:
    </p>
    <ul>
      <li>Tax deferred individual compensation programs</li>
      <li>Custom designed owner/manager benefit plans</li>
      <li>Individual pension plans for significant shareholders</li>
      <li>Unlimited supplementary pension plans</li>
      <li>Forward averaging of bonuses</li>
      <li>Hidden financing opportunities</li>
      <li>Living buyout strategies</li>
      <li>Tax deferred buyout arrangements</li>
      <li>Enhanced share redemption strategies</li>
    </ul>
  </Container>
</template>

<script>
import Container from "@/components/Container.vue";
import Header from "@/components/Header.vue";

export default {
  components: {
    Container,
    Header,
  },
};
</script>
